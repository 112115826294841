import {animateInViewport} from "./transition";

let jQueryBridget = require( 'jquery-bridget' );
let Masonry = require( 'masonry-layout' );

jQueryBridget( 'masonry', Masonry, $ );

$( function() {
	$( 'html,body' ).scrollTop( 0 );

	setTimeout( function() {
		$( '.site-overlay' ).css( {
			opacity: 0,
		} );
	}, 200 );

	window.msnry = $( '.masonry' ).on( 'layoutComplete', function() {
		animateInViewport();
	} ).masonry( {
		itemSelector: '.masonry-item',
		gutter: 24,
		columnWidth: '.masonry-item:not(.hidden):not(.filtered)',
		transitionDuration: 0,
		horizontalOrder: true,
	} );

	updateFixedFooter();
} );

var resizeTimer;

$( window ).on( 'resize', function() {
	clearTimeout( resizeTimer ); // Clear the previous timer

	resizeTimer = setTimeout( function() {
		// Your code to be executed after 100ms of inactivity
		updateFixedFooter();
	}, 100 );
} );

function updateFixedFooter() {
	let footerHeight = $( '#footer' ).outerHeight();

	$( '#content' ).css( {
		'margin-bottom': footerHeight + 'px',
	} );
}
