import {FilterData, FilteringFlow} from "@filtering.js/filtering.js/ui";
import {animateInViewport} from "../transition";
import {updateGoogleMapMarkers} from "./map";
import {loadMore} from "../load-more";

$( function() {
	var $filtering = $( '.filtering' );
	if ( $filtering.length ) {
		var filteringElement = $filtering[ 0 ];

		function updateFilterContainer( $this ) {
			$filtering.find( '.filter-items-container' )
				.stop().slideUp( 'fast' )
				.removeClass( 'expanded' );
			$filtering.find( '.filter-group' ).find( '.filter-group-arrow' )
				.removeClass( '-rotate-90' ).addClass( 'rotate-90' );
			if ( $this ) {
				$this.parents( '.filter-group' ).find( '.filter-items-container' )
					.stop().slideDown( 'fast' )
					.addClass( 'expanded' );
				$this.parents( '.filter-group' ).find( '.filter-group-arrow' )
					.removeClass( 'rotate-90' ).addClass( '-rotate-90' );
			}
		}

		class MyFilteringFlow extends FilteringFlow {
			handleFilterResult( result, filterData ) {
				super.handleFilterResult( result );
				$( '.no-results' ).toggleClass( 'hidden', result.filteredItems.length > 0 );

				this.updateLoadMore( result );

				let allFilterChecked = true;
				for ( const groupResult of result.groups ) {
					for ( const filterResult of groupResult.filters ) {
						const filterElement = filterResult.schemaFilter.data.element;

						const totalElement = filterElement.querySelector( '.filtering-total' );
						if ( totalElement ) {
							totalElement.innerText = filterResult.possibleItems.length;
						}

						$( `.items-group-header[data-year="${filterResult.schemaFilter.name}"]` ).toggleClass( 'hidden', filterResult.filteredItems.length === 0 );
						if ( filterElement.classList.contains( 'checked' ) ) {
							let groupTitle = '';
							if ( filterElement.dataset.filterType === 'all' ) {
								groupTitle = $( filterElement ).parents( '.filter-group' ).find( '.filter-group-title' ).attr( 'data-default-title' );
							} else {
								groupTitle = filterElement.innerHTML;
							}
							$( filterElement ).parents( '.filter-group' ).find( '.filter-group-title' ).html( groupTitle );
						}

						if ( filterElement.dataset.filterType !== 'all' && filterElement.classList.contains( 'checked' ) ) {
							allFilterChecked = false;
						}
					}
				}

				$( '.blog .filter-items-container .filtering-filter.disabled' ).hide();
				updateFilterContainer();
				window.msnry.masonry();
				animateInViewport();

				$( '.enable-all-filters' ).toggleClass( 'checked', allFilterChecked );

				this.updateGoogleMarkers( result );

				storeFiltersToHash( filterData );
			}

			updateLoadMore( result ) {
				if ( $( '.load-more' ).length === 0 ) {
					return;
				}

				for ( const item of result.allItems ) {
					item.data.element.classList.add( 'load-more-hidden' );
					item.data.element.querySelector( '.property' ).classList.remove( 'active' )
				}
				result.filteredItems.map( function( item ) {
					const element = item.data.element;
					return {
						index: parseInt( element.dataset.index ),
						element: element
					};
				} ).sort( function( e1, e2 ) {
					return e2.index - e1.index;
				} ).forEach( function( item ) {
					item.element.parentElement.prepend( item.element );
				} );
				loadMore( $( '.load-more' ) );
			}

			updateGoogleMarkers( result ) {
				const ids = [];
				for ( const item of result.filteredItems ) {
					ids.push( item.data.element.id );
				}
				updateGoogleMapMarkers( ids );
			}
		}

		$( document ).click( function( event ) {
			if ( !$( event.target ).closest( '.filter-group' ).length ) {
				updateFilterContainer();
			}
		} );

		$filtering.find( '.filter-group .filter-group-toggle' ).on( 'click', function() {
			var $this = $( this );
			if ( $this.parents( '.filter-group' ).find( '.filter-items-container' ).hasClass( 'expanded' ) ) {
				updateFilterContainer();
			} else {
				updateFilterContainer( $this );
			}
		} );

		const storeHash = filteringElement.dataset.storeHash === 'true';
		const filteringFlow = new MyFilteringFlow( filteringElement, {
			filteredItemClass: 'hidden',
			triggerFilterAfterInitializing: !storeHash,
		} );
		window.addEventListener( 'hashchange', loadFiltersFromHash );
		if ( storeHash ) {
			loadFiltersFromHash();
		}

		function loadFiltersFromHash() {
			if ( !storeHash ) {
				return;
			}

			const hash = decodeURIComponent( window.location.hash.substring( 1 ) );
			const filteringData = new FilterData();
			if ( hash ) {
				const filteringAsQueryParams = new URLSearchParams( hash );
				for ( const [groupName, filterName] of filteringAsQueryParams ) {
					filteringData.checkFilter( groupName, filterName );
				}
			}
			filteringFlow.filter( filteringData );
		}

		function storeFiltersToHash( filterData ) {
			if ( !storeHash ) {
				return;
			}

			const filteringAsQueryParams = new URLSearchParams();
			for ( const [groupName, checkedFilters] of filterData.checkedFilters ) {
				for ( const checkedFilter of checkedFilters ) {
					filteringAsQueryParams.append( groupName, checkedFilter );
				}
			}

			const newHash = filteringAsQueryParams.toString();
			updateHash( newHash );
		}

		function updateHash( newHash ) {
			if ( newHash !== window.location.hash.substring( window.location.hash.indexOf( '#' ) + 1 ) ) {
				if ( newHash ) {
					window.location.hash = newHash;
				} else {
					const uri = window.location.toString();
					history.pushState( true, '', uri.substring( 0, uri.indexOf( "#" ) ) );
				}
			}
		}
	}
} );
