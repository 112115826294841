import {animateInViewport} from "../transition";

$( '.image-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: true,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 0,
		breakpoints: {
			1024: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.posts-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1.1,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
		},
	} );

	let slidersCount = swiper.slides.length;
	let $progressBar = $this.find( '.progress-bar' );

	$progressBar.css( { 'width': 100 / slidersCount + '%' } );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();

		$progressBar.css( { 'width': (100 / slidersCount) * (this.activeIndex + 1) + '%' } );
	} );
} );

$( '.properties-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: false,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 2.3,
				spaceBetween: 20,
			},
			1680: {
				slidesPerView: 3.3,
				spaceBetween: 20,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.simple-posts-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: true,
		autoHeight: false,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.hero-carousel' ).each( function() {
	var $this = $( this );

	function playVideos( swiper ) {
		forEachVideoSlide( swiper, function( $slide, $video ) {
			if ( slideVisible( $slide ) ) {
				$video.each( function() {
					console.log( 'Play video in hero slider' );
					this.play();
				} );
			}
		} );
	}

	function pauseVideos( swiper ) {
		forEachVideoSlide( swiper, function( $slide, $video ) {
			if ( !slideVisible( $slide ) ) {
				$video.each( function() {
					this.pause();
				} );
			}
		} );
	}

	function pauseAllVideos( swiper ) {
		forEachVideoSlide( swiper, function( $slide, $video ) {
			$video.each( function() {
				this.pause();
			} );
		} );
	}

	function updateVideos( swiper ) {
		pauseVideos( swiper );
		playVideos( swiper );
	}

	function forEachVideoSlide( swiper, callback ) {
		for ( let i = 0; i < swiper.slides.length; i++ ) {
			const $slide = $( swiper.slides[ i ] );
			const $videos = $slide.find( 'video' );
			if ( $videos.length ) {
				callback( $slide, $videos );
			}
		}
	}

	function slideVisible( $slide ) {
		return $slide.hasClass( 'swiper-slide-active' ) || $slide.hasClass( 'swiper-slide-visible' );
	}

	var swiper = new Swiper( this, {
		pagination: false,
		loop: true,
		grabCursor: false,
		slidesPerView: 1,
		spaceBetween: 0,
		autoplay: {
			delay: 3000,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ['calc(100% + 5rem)', 0, 0],

			},
			next: {
				translate: [0, 0, -400],
			},
		},
		speed: 1000,
		on: {
			init: ( swiper ) => playVideos( swiper ),
			transitionStart: ( swiper ) => playVideos( swiper ),
			transitionEnd: ( swiper ) => updateVideos( swiper ),
			sliderMove: ( swiper ) => updateVideos( swiper ),
		},
	} );

	let observer = new IntersectionObserver( function( entries, observer ) {
		entries.forEach( ( entry ) => {
			if ( entry.isIntersecting ) {
				playVideos( swiper );
			} else {
				pauseAllVideos( swiper );
			}
		} );
	} );
	observer.observe( this );
} );
